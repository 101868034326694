import styles from './Wrapper.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TYPES = {
    TOP_LEFT: 'top-left',
    TOP_RIGHT: 'top-right',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_LEFT: 'bottom-left'
};

const Wrapper = ({children, className, type}) => (
    <div className={classNames(styles['container'], styles[type], className)}>
        {children}
    </div>
);

Wrapper.TYPES = TYPES;

Wrapper.propTypes = {
    type: PropTypes.oneOf(Object.values(Wrapper.TYPES)).isRequired,
    className: PropTypes.string
};

Wrapper.defaultProps = {
    className: ''
};

export default Wrapper;
