import styles from "./Smartphone.module.scss";

import React, { Component } from "react";

import Map, { Compass, Zoom } from "@jawg/react-jawg-map";
import { StyleSwitcher, LanguageSwitcher } from "@jawg/react-jawg-map-editor";
import Geocoding from "@jawg/react-geocoding";
import config from "config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

import Wrapper from "../../../components/wrapper/Wrapper";

const STYLE = "jawg-streets";

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      map: void 0,
      openMapEditor: false,
      language: 'int',
      style: STYLE,
    };
    this.fixAppHeight();
  }

  /**
   * Fix issus with vh and adress bar
   * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   */
  fixAppHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  };

  closeMapEditor = () => {
    this.setState({ openMapEditor: false });
  };

  handleLanguageClick = (language) => {
    this.setState({ openMapEditor: false, language });
  };

  renderMapEditor = () => {
    const { language, openMapEditor, map, style } = this.state;
    return (
      <div className={styles["map-editor"]}>
        <div
          className={styles["icon"]}
          onClick={() => this.setState({ openMapEditor: !openMapEditor })}
        >
          <FontAwesomeIcon icon={faCog} />
        </div>
        {openMapEditor && (
          <div className={styles["options"]}>
            <LanguageSwitcher
              endpoint={`${config.apiBaseUrl}/styles/${style}.json?access-token=${config.token}&extrude=true&lang=${language}`}
              map={map}
              onClick={this.handleLanguageClick}
              initialSelectedLanguage={language}
            />
            <StyleSwitcher
              map={map}
              endpoint={`${config.apiBaseUrl}/styles/${style}.json?access-token=${config.token}&extrude=true&lang=${language}`}
              onClick={(style) => {
                this.setState({ style });
                this.closeMapEditor();
              }}
              token={config.token}
            />
          </div>
        )}
      </div>
    );
  };

  onMapInit = (map) => {
    this.setState({ map });
  };

  render = () => {
    const { map } = this.state;
    return (
      <div className={styles["container"]}>
        <Map
          mapStyle={`${config.apiBaseUrl}/styles/${STYLE}.json?access-token=${config.token}&extrude=true&lang=int`}
          onInit={this.onMapInit}
          zoom={8}
          scale={true}
          disableAttribution={true}
        />
        {map && (
          <>
            <Wrapper
              type={Wrapper.TYPES.BOTTOM_RIGHT}
              className={styles["map-action"]}
            >
              <div className={styles["compass"]}>
                <Compass map={map} />
              </div>
              <div className={styles["zoom"]}>
                <Zoom map={map} />
              </div>
            </Wrapper>
            <Wrapper
              type={Wrapper.TYPES.TOP_LEFT}
              className={styles["map-top"]}
            >
              <div className={styles["geocoding"]}>
                <Geocoding map={map} fullWidthMode={true} autocomplete={true}>
                  <Geocoding.Routing />
                </Geocoding>
              </div>
              {this.renderMapEditor()}
            </Wrapper>
          </>
        )}
      </div>
    );
  };
}

export default App;
