import styles from "./View3D.module.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/free-solid-svg-icons";

const EVENTS = {
  VIEW_3D: "view-3d"
};

class View3D extends Component {
  state = {
    zoomIn: true
  };

  handleClick = () => {
    const { map, from, to } = this.props;
    const { zoomIn } = this.state;
    map.easeTo(zoomIn ? to : from, true);
    map.fire(EVENTS.VIEW_3D);
    this.setState({ zoomIn: !zoomIn });
  };

  render() {
    return (
      <button
        className={styles["container"]}
        title="View 3D"
        onClick={this.handleClick}
      >
        <FontAwesomeIcon icon={faCube} />
      </button>
    );
  }
}

View3D.EVENTS = EVENTS;

View3D.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired
};

export default View3D;
