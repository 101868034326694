import styles from "./Desktop.module.scss";

import React, { Component } from "react";

import Map, { Compass, Zoom } from "@jawg/react-jawg-map";
import { StyleSwitcher, LanguageSwitcher } from "@jawg/react-jawg-map-editor";
import Geocoding from "@jawg/react-geocoding";
import config from "config";
import places from "places";

import Wrapper from "../../../components/wrapper/Wrapper";
import View3D from "../../../components/view-3d/View3D";
import WorldTour from "../../../components/world-tour/WorldTour";

const STYLE = "jawg-streets";

export class App extends Component {
  state = {
    map: void 0,
    style: STYLE,
    language: 'int',
  };

  onMapInit = (map) => {
    this.setState({ map });
  };

  render = () => {
    const { map, style, language } = this.state;
    const stopWorldTourOnEvents = Object.keys(
      Object.assign({}, Map.EVENTS, View3D.EVENTS, Geocoding.EVENTS)
    ).map((k) => Map.EVENTS[k] || View3D.EVENTS[k] || Geocoding.EVENTS[k]);
    return (
      <div className={styles["container"]}>
        <Map
          mapStyle={`${config.apiBaseUrl}/styles/${STYLE}.json?access-token=${config.token}&extrude=true&lang=${language}`}
          onInit={this.onMapInit}
          zoom={8}
          scale={true}
          disableAttribution={true}
        />
        {map && (
          <>
            <Wrapper type={Wrapper.TYPES.TOP_RIGHT}>
              <div className={styles["language"]}>
                <LanguageSwitcher
                  endpoint={`${config.apiBaseUrl}/styles/${style}.json?access-token=${config.token}&extrude=true&lang=${language}`}
                  map={map}
                  onClick={(language) => {
                    this.setState({ language });
                  }}
                />
              </div>
              <div className={styles["style"]}>
                <StyleSwitcher
                  map={map}
                  endpoint={`${config.apiBaseUrl}/styles/${style}.json?access-token=${config.token}&extrude=true&lang=${language}`}
                  onClick={(style) => {
                    this.setState({ style });
                  }}
                  token={config.token}
                />
              </div>
            </Wrapper>
            <Wrapper
              type={Wrapper.TYPES.BOTTOM_RIGHT}
              className={styles["map-action"]}
            >
              <div className={styles["compass"]}>
                <Compass map={map} />
              </div>
              <View3D
                map={map}
                from={config.view3D.from}
                to={config.view3D.to}
              />
              <div className={styles["zoom"]}>
                <Zoom map={map} />
              </div>
            </Wrapper>
            <Wrapper type={Wrapper.TYPES.TOP_LEFT}>
              <Geocoding map={map} autocomplete={true}>
                <Geocoding.Routing />
              </Geocoding>
            </Wrapper>
            <Wrapper
              type={Wrapper.TYPES.BOTTOM_LEFT}
              className={styles["word-tour"]}
            >
              <WorldTour
                map={map}
                places={places}
                stopOnEvents={stopWorldTourOnEvents}
              />
            </Wrapper>
          </>
        )}
      </div>
    );
  };
}

export default App;
