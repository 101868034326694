import styles from "./WorldTour.module.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const STOP_WORLD_TOUR_EVENT = "stop-world-tour";

export class WorldTour extends Component {
  state = {
    isRunning: false,
    indexSelected: -1
  };

  componentDidMount() {
    const { map, durationOnPlace, stopOnEvents } = this.props;
    this.flying = false;

    map.on("flystart", () => {
      this.flying = true;
    });
    map.on("flyend", () => {
      this.flying = false;
      this.triggerMove(true, durationOnPlace);
    });
    map.on("moveend", () => {
      if (this.flying) {
        map.fire("flyend");
      }
    });
    map.on(STOP_WORLD_TOUR_EVENT, this.stop);
    map.on("mousedown", this.stop);
    map.on("wheel", this.stop);
    stopOnEvents.forEach(event => {
      map.on(event, this.stop);
    });
  }

  selectPlace = index => {
    this.setState({ indexSelected: index });
    this.resetTimer();
    this.goToPlace(index);
  };

  resetTimer = () => {
    if (this.timer !== void 0) {
      clearTimeout(this.timer);
      this.timer = void 0;
    }
  };

  triggerMove = (next, duration) => {
    const { places } = this.props;
    this.timer = setTimeout(() => {
      const { indexSelected } = this.state;
      const newIndex = next
        ? indexSelected === places.length - 1
          ? 0
          : indexSelected + 1
        : indexSelected === -1
        ? 0
        : indexSelected;
      this.setState({ indexSelected: newIndex });
      this.goToPlace(newIndex);
    }, duration);
  };

  startAndStopWorldTour = () => {
    const { map } = this.props;
    const { isRunning } = this.state;
    if (isRunning) {
      map.stop();
      this.stop();
    } else {
      this.triggerMove(false, 0);
      this.setState({ isRunning: true });
    }
  };

  goToPlace = index => {
    const { map, places } = this.props;
    this.flying = false;
    const element = document.getElementById(`place-${index}`);
    element.scrollIntoView({ behavior: "smooth", inline: "center" });
    const place = places[index];
    map.easeTo(
      {
        center: place.center,
        zoom: place.zoom,
        bearing: place.bearing,
        pitch: place.pitch,
        speed: 0.5
      },
      true
    );
    map.fire("flystart");
  };

  stop = () => {
    this.resetTimer();
    this.flying = false;
    this.setState({ isRunning: false });
  };

  render() {
    const { places } = this.props;
    const { indexSelected, isRunning } = this.state;
    return (
      <div className={styles["container"]}>
        <button
          className={styles["start-button"]}
          onClick={this.startAndStopWorldTour}
        >
          <FontAwesomeIcon icon={isRunning ? faPause : faPlay} />
          <span>World Tour</span>
        </button>
        <Scrollbars autoHeight className={styles["scrollbars"]}>
          <div
            className={classNames(styles["list"], {
              [styles["show"]]: isRunning
            })}
          >
            {places.map((place, i) => (
              <div
                key={`${place.center[0]}-${place.center[1]}`}
                id={`place-${i}`}
                className={classNames(styles["place"], {
                  [styles["selected"]]: indexSelected === i
                })}
                onClick={() => this.selectPlace(i)}
              >
                <span className={styles["name"]}>{place.name}</span>
                <span className={styles["country"]}>{place.country}</span>
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

WorldTour.propTypes = {
  // Props
  places: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      center: PropTypes.arrayOf(PropTypes.number),
      zoom: PropTypes.number,
      bearing: PropTypes.number,
      pitch: PropTypes.number
    })
  ),
  durationOnPlace: PropTypes.number,
  map: PropTypes.object.isRequired,
  stopOnEvents: PropTypes.arrayOf(PropTypes.string)
};

WorldTour.defaultProps = {
  durationOnPlace: 5000,
  stopOnEvents: []
};

WorldTour.STOP_WORLD_TOUR_EVENT = STOP_WORLD_TOUR_EVENT;

export default WorldTour;
