import React, { Component } from "react";

import DesktopApp from "./desktop/Desktop";
import SmartphoneApp from "./smartphone/Smartphone";

export class App extends Component {
  render = () => {
    if (window.matchMedia("(min-width: 425px)").matches) {
      return <DesktopApp />;
    } else {
      return <SmartphoneApp />;
    }
  };
}

export default App;
